import { Button, Table } from 'antd';
import { React, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  biggestWinIcon,
  biggestWinIconHover,
  latestEarningsIcon,
  latestEarningsIconHover,
  liveEarningsIcon,
  liveEarningsIconHover,
  defaultThumbnailIcon,
  profileMyProfileIcon,
} from '../../images';
import { getHighestEarnings } from '../../api/highestEarnings';
import { getGameCred } from '../../api/getGameCred';
import { SettingActions } from '../reducers/settingReducer';
import { maskUsername } from '../common/general';
import { formatAmount } from '../common/common.js';

function HomeHighestEarnings({ branch }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [activeTab, setActiveTab] = useState('0');
  const { t } = useTranslation('common');
  const [tableData, setTableData] = useState([]);
  const [filterCategory, setFilterCategory] = useState('liveEarnings');
  const isMobile = useSelector((state) => state.setting.isMobile);
  const isLoading = useSelector((state) => state.setting.isLoading);

  // pagination
  const limit = 10;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const handleOnClickTab = (tab) => {
    setActiveTab(tab);
  };

  const getData = async () => {
    dispatch(SettingActions.setLoading(true));
    try {
      let query = {
        branchId: process.env.BRANCH_ID,
        '$sort[multiplier]': -1,
        '$sort[time]': -1,
        $limit: limit,
      };
      switch (activeTab) {
        case '0': // LIVE - Hourly
        default:
          // query['time[$gte]'] = moment().subtract(1, 'hour').toISOString(); // hourly
          query['time[$gte]'] = moment().subtract(1, 'day').toISOString();
          break;
        case '1': // WEEKLY - Weekly
          query['time[$gte]'] = moment().subtract(1, 'week').toISOString();
          break;
        case '2': // Biggest - All-Time High
          break;
      }

      const result = await getHighestEarnings(query);

      const dataSource = [];
      let obj = {};

      if (result.data.length > 0) {
        for (let i = 0; i < result.data.length; i++) {
          const data = result.data[i];

          obj = {
            ...data,
            game: data?.brandGame?.name,
            user: data?.username,
            timeCol: data?.time,
            betAmount: data?.betAmt,
            multiplier: data?.multiplier,
            payout: data?.payoutAmt,
            thumbnail: data?.brandGame?.thumbnail,
          };
          dataSource.push(obj);
        }
      }
      setTableData(dataSource);
    } catch (error) {
      console.error('Error: ', error);
    }
    dispatch(SettingActions.setLoading(false));
  };

  useEffect(() => {
    getData();
    dispatch(SettingActions.setLoading(false));
  }, [page, activeTab]);

  const handleThumbnail = async (data) => {
    dispatch(SettingActions.setLoading(true));
    if (!Cookies.get('accessToken')) {
      router.push('/login');
    } else {
      const gameInfo = await getGameCred({
        brandId: data.brandId,
      });

      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo) {
        dispatch(SettingActions.setLoading(false));
        const gamePath = `/game_website?brandId=${data.brandId}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${data.brandGame._id}`;
        return router.push(gamePath);
      }
    }
  };

  const tabItems = [
    {
      key: '0',
      label: t('transaction.live'),
      icon: liveEarningsIcon,
      iconHover: liveEarningsIconHover,
    },
    {
      key: '1',
      label: t('transaction.weekly'),
      icon: latestEarningsIcon,
      iconHover: latestEarningsIconHover,
    },
    {
      key: '2',
      label: t('transaction.biggestWin'),
      icon: biggestWinIcon,
      iconHover: biggestWinIconHover,
    },
  ];

  const columnsDesktop = [
    {
      title: '',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'left',
      width: '10%',
      render: (item, row) => (
        row.thumbnail
          ? (
            <div className='p-2 flex gap-2 items-center justify-end align-center hover:cursor-pointer' onClick={() => (handleThumbnail(row))}>
              <img
                src={row.thumbnail}
                width={60}
                height={60}
              />
            </div>
          )
          : (
            <div className='p-2 flex gap-2 items-center justify-end align-center hover:cursor-pointer' onClick={() => (handleThumbnail(row))}>
              <Image
                src={defaultThumbnailIcon}
                alt='skeleton image of game'
                width={60}
                height={60}
              />
            </div>
          )
      ),
    },
    {
      title: t('home.game'),
      dataIndex: 'game',
      key: 'game',
      align: 'left',
      width: '15%',
      render: (item, row) => (
        <div className='flex gap-2 items-center justify-start align-center'>
          {/* <div className='leading-none' style={{ width: 38 }}><img src={item?.gameIcon || CampaignsRank1} alt='' /></div> */}
          <div className='capitalize text-white text-tiny'>{row?.game}</div>
        </div>
      ),
    },
    {
      title: t('login.player'),
      dataIndex: 'user',
      key: 'user',
      align: 'left',
      width: '10%',
      render: (item, row) => (
        <div className='flex gap-2 items-center justify-start align-center'>
          <div className='leading-none' style={{ width: 28 }}><Image src={profileMyProfileIcon} alt='' /></div>
          <div className='text-white text-tiny'>{maskUsername(row?.user)}</div>
        </div>
      ),
    },
    {
      title: t('common.time'),
      dataIndex: 'timeCol',
      key: 'timeCol',
      align: 'center',
      width: '10%',
      render: (item, row) => {
        const dateObject = new Date(row.timeCol);
        const timeString = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        return (
          <div className='flex justify-between items-center text-tiny p-1'>
            <div className='flex text-[#ffffffa3]'>
              {moment(row.time).format('DD/MM/YY')}
            </div>
            <div className='flex justify-center items-center align-center gap-1 text-[#ffffffa3]'>
              <ClockCircleOutlined />
              {timeString}
            </div>
          </div>
        );
      },
    },
    {
      title: t('transaction.bet'),
      dataIndex: 'betAmount',
      key: 'betAmount',
      align: 'center',
      width: '10%',
      render: (item, row) => (
          <div className='text-tiny'>{formatAmount(row?.betAmount)}</div>
      ),
    },
    {
      title: t('common.multiplier'),
      dataIndex: 'multiplier',
      key: 'multiplier',
      align: 'left',
      width: '10%',
      render: (item, row) => (
        <>
        <div className='p-1 bg-[#0DBF7B] rounded-sm text-tiny text-center' style={{ maxWidth: 'fit-content', minWidth: 45 }}>
          {row.multiplier?.toFixed(2) ?? 0}
          x
        </div>
        </>
      ),
    },
    {
      title: t('common.payout'),
      dataIndex: 'payout',
      key: 'payout',
      align: 'center',
      width: '15%',
      render: (item, row) => (
        <div className='flex justify-center pr-2'>
          <div className={`text-tiny text-white p-1 ${(row?.payout)?.toFixed(2) >= 0 ? 'text-black bg-[#0DBF7B]' : 'bg-[#FF3C30]'}  w-full rounded-sm`}>
            {/* {depositCurrency}
            {' '} */}
            {formatAmount(row?.payout)}
          </div>
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      title: '',
      dataIndex: 'user',
      key: 'user',
      render: (item, row, index) => {
        const dateObject = new Date(row.timeCol);
        const timeString = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        const oddNumber = (index % 2 !== 0);

        return (
          <div className='p-3 flex' style={{ backgroundColor: oddNumber ? 'rgba(33,47,61,1)' : '' }}>

            <div className='flex mr-4'>
              {
                row.thumbnail
                  ? (
                    <div className='flex gap-2 items-center justify-center align-center hover:cursor-pointer' onClick={() => (handleThumbnail(row))}>
                      <img
                        src={row.thumbnail}
                        width={60}
                        height={60}
                        className='transition-transform duration-500 ease-in-out transform hover: scale-110'
                      />
                    </div>
                  )
                  : (
                    <div className='flex gap-2 items-center justify-center align-center hover:cursor-pointer' onClick={() => (handleThumbnail(row))}>
                      <Image
                        src={defaultThumbnailIcon}
                        alt='skeleton image of game'
                        width={60}
                        height={60}
                        className='transition-transform duration-500 ease-in-out transform hover: scale-110'
                      />
                    </div>
                  )
              }
            </div>

            <div className='flex flex-col flex-grow '>

              <div className='two-row-cell-top'>

                <div className='flex gap-2 items-center justify-center align-center'>
                  <div className='leading-none' style={{ width: 28 }}>
                    <Image src={profileMyProfileIcon} alt='' />
                  </div>
                  <div className='text-white text-xs'>
                    {maskUsername(row?.user)}
                  </div>
                </div>

                <div className='flex gap-2 items-center justify-center align-center'>
                  <div className='flex items-center justify-center align-center text-xs'>
                    {moment(row.time).format('DD/MM/YY')}
                  </div>
                  <div className='flex gap-1'>
                    <span className='flex justify-center'>
                      <ClockCircleOutlined />
                    </span>
                    <div className='leading-none text-xs'>
                      {timeString}
                    </div>
                  </div>
                </div>

              </div>

              <div className='two-row-cell-bottom'>

                <div className='flex w-2/5 gap-1 items-center align-center sm:w-1/2'>
                  <div className='bg-[#455a6c] rounded-sm p-1 px-2 flex items-center justify-center align-center'>
                    {/* <div className='text-xs'>
                      {depositCurrency}
                    </div> */}
                  </div>
                  <div className='text-xs'>
                    {formatAmount(row?.betAmount)}
                  </div>
                </div>

                <div className='flex w-3/5 gap-1 items-center sm:w-1/2'>
                  <div className='w-2/6 p-1 bg-[#0DBF7B] rounded-sm text-xs'>
                    {row.multiplier}
                    x
                  </div>
                  <div className={`w-4/6 p-1 text-xs text-white rounded-sm ${(row?.payout)?.toFixed(2) >= 0 ? 'text-black bg-[#0DBF7B]' : 'bg-[#FF3C30]'}`}>
                    {/* {depositCurrency}{' '} */}
                    {formatAmount(row?.payout)}
                  </div>
                </div>

              </div>

            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className='text-xl md:text-lg font-bold border-customgray mt-4'>{t('home.highestEarnings')}</div>

      <div className='flex w-full mb-4 pt-2 border-b-2 border-customgray overflow-x-scroll'>
        {tabItems.map((tab) => (
          <Button
            key={`tabs-${tab.key}`}
            className={`justify-start px-4 mx-4 py-2 uppercase text-white hover:bg-gray-800 ${activeTab === tab.key ? 'border-b-2  border-customgreen' : ''
            }`}
            onClick={() => handleOnClickTab(tab.key)}
          >
            <div className='flex gap-2 align-center justify-center items-center'>
              <div className='flex align-center justify-center items-center'>
                <div className='leading-none' style={{ width: 16 }}>
                  <Image src={activeTab === tab.key ? tab.iconHover : tab.icon} />
                </div>
              </div>
              <p className={`${activeTab === tab.key ? 'text-customgreen' : 'text-white'} font-semibold text-tiny`}>{tab.label}</p>
            </div>
          </Button>
        ))}
      </div>

      <div className='highest-earnings-table overflow-x-scroll'>
        {
          isMobile
            ? (
              <Table
                dataSource={tableData}
                columns={columnsMobile}
                pagination={false}
              />
            )
            : (
              <Table
                dataSource={tableData}
                columns={columnsDesktop}
                pagination={false}
              />
            )
        }
      </div>
    </div>

  );
}

export default HomeHighestEarnings;
