import { ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState, useEffect } from 'react';

function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const elementWindow = typeof window !== 'undefined'
    && (document.getElementById('page-container') || null);

  useEffect(() => {
    const navbar = document.getElementById('header-navbar');
    if (navbar) {
      const updateVisibility = () => {
        if (navbar.classList.contains('header-navbar-hidden')) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      const observer = new MutationObserver(updateVisibility);
      observer.observe(navbar, {
        attributes: true,
        attributeFilter: ['class'],
      });

      updateVisibility();

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const scrollToTop = () => {
    const nav = document.getElementById('header-navbar');
    const leftSider = document.getElementById('left-sider');

    const topElement = document.getElementById('top');
    topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    if (elementWindow.scrollTop >= 58) {
      nav?.classList.remove('header-navbar-hidden');
      leftSider?.classList.remove('leftSider-padding');
    }
  };

  return (
    <div className={`fixed bottom-20 md:bottom-4 z-10 right-5 md:right-12 ${!isVisible ? 'hidden' : ''}`}>

      <Button
        onClick={scrollToTop}
        className='bg-customgreen text-white font-bold pb-2 pt-1 px-2 rounded-full'
      >
        <ArrowUpOutlined style={{ fontSize: '24px' }} />
      </Button>

    </div>
  );
}

export default BackToTopButton;
