const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getHighestEarnings = (params) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/live-transactions`, 
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${
      //   Cookies.get('accessToken')
      // }`,
    },
    params
  })
  .then((response) => response?.data)
  .catch((error) => error);

module.exports = {
  getHighestEarnings,
};
