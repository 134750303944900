import {
  homeFilterAllIcon, homeFilterCardGamesIcon, homeFilterFishingIcon, homeFilterLiveCasinoIcon, homeFilterPopularIcon, homeFilterRecentlyPlayedIcon, homeFilterSlotsIcon, homeFilterSportsIcon,
  homeFilterRecentlyPlayedIconHover,
  homeFilterFishingIconHover,
  homeFilterCardGamesIconHover,
  homeFilterLiveCasinoIconHover,
  homeFilterSlotsIconHover,
  homeFilterPopularIconHover,
  homeFilterSportsIconHover,
  homeFilterAllIconHover,
} from '../../images';

export const homeFilterList = [
  {
    icon: homeFilterAllIcon,
    iconHovered: homeFilterAllIconHover,
    name: 'game.all',
    key: '0',
    type: '',
    style: { minWidth: 80 },
  },
  // {
  //   icon: homeFilterSportsIcon,
  //   iconHovered: homeFilterSportsIconHover,
  //   name: 'Sports',
  //   key: '1',
  //   style: { minWidth: 80 },
  // },
  {
    icon: homeFilterPopularIcon,
    iconHovered: homeFilterPopularIconHover,
    name: 'game.popular',
    key: '2',
    type: 'popular',
    style: { minWidth: 80 },
  },
  {
    icon: homeFilterSlotsIcon,
    iconHovered: homeFilterSlotsIconHover,
    name: 'game.slots',
    key: '3',
    type: 0,
    style: { minWidth: 80 },
  },
  {
    icon: homeFilterLiveCasinoIcon,
    iconHovered: homeFilterLiveCasinoIconHover,
    name: 'game.liveCasino',
    key: '4',
    type: 8,
    style: { minWidth: 80 },
  },
  {
    icon: homeFilterCardGamesIcon,
    iconHovered: homeFilterCardGamesIconHover,
    name: 'game.tableGames',
    key: '5',
    type: 4,
    style: { minWidth: 90 },
  },
  {
    icon: homeFilterFishingIcon,
    iconHovered: homeFilterFishingIconHover,
    name: 'game.instantGames',
    type: 1,
    key: '6',
  },
  {
    icon: homeFilterRecentlyPlayedIcon,
    iconHovered: homeFilterRecentlyPlayedIconHover,
    name: 'game.recentlyPlayed',
    key: '7',
    type: 'recent',
    style: { minWidth: 120 },
  },

];
