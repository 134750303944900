import { CloseCircleFilled } from '@ant-design/icons';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import {
  isMobileSafari, isIOS, isAndroid, isMobile, isSafari, isIPhone13, isIPad13,
} from 'react-device-detect';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import {
  marqueeDesktopEN,
  marqueeDesktopPT,
  marqueeAndroidEN,
  marqueeAndroidPT,
  marqueeIosEN,
  marqueeIosPT,
} from '../../images/index';

const apkDownloadUrl = 'https://ppx7-main.s3.ap-southeast-1.amazonaws.com/apk/ppx7.apk';

export default function App({ show, toggleShow, userAgent }) {
  const { t, i18n } = useTranslation('common');
  const [isMarquee, setIsMarquee] = useState(marqueeDesktopPT);

  useEffect(() => {
    let marqueeImage = isMarquee;
    if (isMobile) {
      if (isAndroid) {
        switch (i18n.language) {
        case 'en-BR':
          marqueeImage = marqueeAndroidEN;
          break;
        case 'pt-BR':
          marqueeImage = marqueeAndroidPT;
          break;
        default:
          marqueeImage = marqueeAndroidPT;
          break;
        }
      }
      if (isMobileSafari || isIOS || isSafari || isIPhone13 || isIPad13) {
        switch (i18n.language) {
        case 'en-BR':
          marqueeImage = marqueeIosEN;
          break;
        case 'pt-BR':
          marqueeImage = marqueeIosPT;
          break;
        default:
          marqueeImage = marqueeIosPT;
          break;
        }
      }
    } else {
      switch (i18n.language) {
      case 'en-BR':
        marqueeImage = marqueeDesktopEN;
        break;
      case 'pt-BR':
        marqueeImage = marqueeDesktopPT;
        break;
      default:
        marqueeImage = marqueeDesktopPT;
        break;
      }
    }
    setIsMarquee(marqueeImage);
  }, [i18n.language]);

  // testing only
  // let device;
  // if (isMobile) {
  //   if (isAndroid) {
  //     device = 'Android';
  //   } else if (isIPhone) {
  //     device = 'iPhone';
  //   } else {
  //     device = 'Other mobile device';
  //   }
  // } else {
  //   device = 'Desktop';
  // }
  // console.log(`You are using a ${device}`);

  function openLink() {
    if (isMobile && isAndroid) {
      window.open(apkDownloadUrl, '_blank');
    }
  }

  if (show) {
    return (
      <div
        tabIndex='-1'
        className={
          show
            ? 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
            : 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
        }
      >
        <div
          className='overlay-closeable'
          onClick={() => {
            toggleShow(false);
            Cookies.set('popup', true);
            Cookies.set('refreshDate', (new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0))));
          }}
        />
        <div
          className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'
          style={{ background: 'none' }}
        >
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-6 md:top-1'>
            <button
              className='rounded-full close-button relative z-10'
              onClick={() => {
                toggleShow(false);
                Cookies.set('popup', true);
                Cookies.set('refreshDate', (new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0))));
              }}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70%', // 100% - 10% padding top - 10% padding bottom
            padding: '10%',
          }}
          onClick={() => openLink()}
        >
          <div style={{ position: 'relative' }}>
            {/* <div style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', zIndex: 1 }}> */}
            {/* {device} */}
            {/* hello world
            </div> */}
            <Image src={isMarquee} width={600} height={700} alt='Download APK Now' onClick={() => openLink()} />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export async function getServerSideProps(context) {
  const userAgent = context.req.headers['user-agent'] || '';
  return { props: { userAgent } };
}
