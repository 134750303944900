/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-quotes */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  DepositFail,
  DepositSuccess,
  HomeMiddleBanner,
  HomeMiddleBannerMobile,

  telegramDesktopEN_BG,
  telegramDesktopPT_BG,
  telegramMobileEN_BG,
  telegramMobilePT_BG,
  luckySpinDesktopEN_BG,
  luckySpinDesktopPT_BG,
  luckySpinMobileEN_BG,
  luckySpinMobilePT_BG,
  giftDesktopEN_BG,
  giftDesktopPT_BG,
  giftMobileEN_BG,
  giftMobilePT_BG
} from '../../images/index';
import BackToTopButton from '../common/BackToTop';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import PopupMarqueeModal from '../dialog/PopupMarqueeModal';
import PopupModal from '../dialog/PopupModal';
import Header from '../general/Header';
import Layout from '../general/Layout';
import { SettingActions } from '../reducers/settingReducer';
import HomeGames from './HomeGames';
import HomeHighestEarnings from './HomeHighestEarnings';
import JackpotComp from './JackpotComp';
import PartnerSection from './PartnerSection';
import { switchLanguage } from '../common/common';
import PopupGamiModal from '../dialog/PopupGamiModal';

const secondLayerBannerContent = [
  {
    link: 'https://t.me/ppx7brazil',
    desktopBG: telegramDesktopEN_BG,
    desktopPT_BG: telegramDesktopPT_BG,
    mobileBG: telegramMobileEN_BG,
    mobilePT_BG: telegramMobilePT_BG
  },
  {
    link: 'https://www.ppx7.com/spinwheel',
    desktopBG: luckySpinDesktopEN_BG,
    desktopPT_BG: luckySpinDesktopPT_BG,
    mobileBG: luckySpinMobileEN_BG,
    mobilePT_BG: luckySpinMobilePT_BG
  },
  {
    link: 'https://www.ppx7.com/en-BR/daily-free-credit',
    desktopBG: giftDesktopEN_BG,
    desktopPT_BG: giftDesktopPT_BG,
    mobileBG: giftMobileEN_BG,
    mobilePT_BG: giftMobilePT_BG
  },
]

function HomePage({ data }) {
  const {
    banners, popupBanners, gameBrands, promotionPost, createdAt
  } = data;

  const router = useRouter();
  const { depositStatus } = router.query;
  const dispatch = useDispatch();

  const popUp = Cookies.get('popup');
  const isLogin = Cookies.get('accessToken');

  const refreshDate = Cookies.get('refreshDate');

  const { t, i18n } = useTranslation('common');
  const ref = useRef(null);
  const firstLoad = useRef(true);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const windowSize = useSelector((state) => state.setting.windowSize);
  const [announcementData, setAnnouncementData] = useState('');

  const [height, setHeight] = useState(0);
  const [hotGames, setHotGames] = useState([]);
  const [recommendedGames, setRecommendedGames] = useState([]);
  const [depositNotificationShow, setDepositNotificationShow] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [validBanners, setValidBanners] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupBannersState, setPopupBannersState] = useState([]);
  const [showPopupGami, setShowPopupGami] = useState(false);
  const [showMarqueePopup, setShowMarqueePopup] = useState(false);

  useEffect(() => {
    if (popupBanners?.length > 0) setPopupBannersState(_.sortBy(popupBanners.filter((x) => x.isVisible), ['sorting']));
  }, [popupBanners]);

  useEffect(() => {
    if (depositStatus) {
      setPaymentStatus(depositStatus);
      setDepositNotificationShow(true);
    }
  }, []);

  useEffect(() => {
    let result = banners?.filter((i) => i.isVisible);
    result = _.sortBy(result, ['sorting']);

    setValidBanners(result);
  }, [banners, isMobile]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      for (let i = 0; i <= 3; i++) {
        if (height <= 0) {
          setTimeout(() => {
            if (ref?.current?.clientHeight) {
              setHeight(ref.current.clientHeight);
            }
          }, 500);
        } else {
          break;
        }
      }
    } else if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });

  useEffect(() => {
    const result = {
      recommendedGames: [],
      hotGames: [],
    };
    data?.brandIcons?.map((x) => {
      x.games.map((x1) => {
        data?.hotGames?.map((x2, i2) => {
          if ((x1._id === x2.gameId) || (x1.gameId === x2.gameId)) {
            let value = {};
            value = { ...x1 };
            value.brandThumbnail = x.imageUrl;
            value.brandName = x.name;
            value.brandId = x.brandId;
            value.thumbnail = x2.url;
            value.sorting = x2.sorting;
            value.isVisible = x2.isVisible;
            result.hotGames.push(value);
          }
        });
        data?.recommendedGames?.map((x2, i2) => {
          if ((x1._id === x2.gameId) || (x1.gameId === x2.gameId)) {
            let value = {};
            value = { ...x1 };
            value.brandThumbnail = x.imageUrl;
            value.brandName = x.name;
            value.brandId = x.brandId;
            value.thumbnail = x2.url;
            value.sorting = x2.sorting;
            value.isVisible = x2.isVisible;
            result.recommendedGames.push(value);
          }
        });
      });
    });

    result.hotGames.filter((game) => game.isVisible === true).map((x) => {
      data?.brandIcons?.map((x1) => {
        if (x1.brandId === x.brandId) {
          // x.brandThumbnail = x1.imageUrl;
          x.brandThumbnail = x1.imageUrl_sec || x1.imageUrl;
        }
      });
    });
    result.recommendedGames.filter((game) => game.isVisible === true).map((x) => {
      data?.brandIcons?.map((x1) => {
        if (x1.brandId === x.brandId) {
          x.brandThumbnail = x1.imageUrl_sec || x1.imageUrl;
        }
      });
    });

    setHotGames(result.hotGames.filter((game) => game.isVisible === true).sort((a, b) => (b.sorting || 0) - (a.sorting || 0)));
    setRecommendedGames(result.recommendedGames.filter((game) => game.isVisible === true).sort((a, b) => (b.sorting || 0) - (a.sorting || 0)));
  }, [data?.hotGames, data?.recommendedGames]);

  useEffect(() => {
    if (!isLogin) {
      if (popupBannersState?.length > 0) {
        if (!refreshDate) {
          // pop modal
          setShowPopup(true);
        } else if (new Date().getTime() > new Date(refreshDate).getTime()) {
          // If next day
          // check pop
          if (popUp !== true) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        }
      }
    }
    // getSocialData();
    dispatch(SettingActions.setLoading(false));
    dispatch(SettingActions.setSelected(null));
  }, [popupBannersState]);

  const getAnnouncementData = async () => {
    // const result = await getAnnouncement({ language: isLanguage });
    // setAnnouncementData(result?.data || '');
    const result = data?.announcement;
    if (result) {
      setAnnouncementData(result[0]?.[switchLanguage(i18n)]);
    }
  };

  useEffect(() => {
    getAnnouncementData();

    dispatch(SettingActions.setMobileMenuSelected(0));
  }, [data?.bonusGroup, i18n.language, data?.announcement]);

  const handleSecondLayerBanner = (link) => {
    window.location.href = link;
  }

  return (
    <Layout selectedIcon={t('common.home')}>
      <div className='px-3 md:px-0'>
        <Header data={data}>
          {/* ads */}
          <PopupModal
            show={showPopup}
            toggleShow={setShowPopup}
            info={popupBannersState}
          />

          <PopupGamiModal
            show={showPopupGami}
            toggleShow={setShowPopupGami}
          />

          <PopupMarqueeModal
            show={showMarqueePopup}
            toggleShow={setShowMarqueePopup}
          />

          <BackToTopButton />

          {
            announcementData
            && (
              <Marquee gradient={false} speed={25} className='bg-customgray p-2'>
                <div
                  className='text-xs gilroy-regular'
                  onClick={() => setShowMarqueePopup(true)}
                  dangerouslySetInnerHTML={{
                    __html: announcementData,
                  }}
                />
              </Marquee>
            )
          }

          {/* Banner */}
          <div id="top">
            <div className='mt-2 mb-2 md:mb-4'>
              <Swiper
                pagination={{
                  clickable: true,
                }}
                navigation
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }}
                loop
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={7}
                slidesPerView={windowSize?.width >= 1200
                  ? 2.5
                  : (windowSize?.width >= 768 ? 2.5 : (windowSize?.width >= 600 ? 1.5 : (windowSize?.width >= 490 ? 1.2 : 1)))}
              >
                {validBanners.map((i, index) => (
                  <SwiperSlide key={`banner-img-b-${index}`} className={`cursor-pointer shine-${index + 1}`}>
                    <div style={{ position: 'relative' }}>
                      <div
                        // key={`banner-img-b-${index}`}
                        // className='leading-unset flex-item-banner'
                        // style={{
                        //   width: windowSize?.width <= 768 ? 310 : 465,
                        // }}
                        onClick={() => {
                          if (i.linkUrl) {
                            window.location.href = i?.linkUrl;
                          } else if (i.activeBonusId) {
                            window.location.href = `/promotions?activeBonusId=${i?.activeBonusId}`;
                          }
                        }}
                      >
                        <img
                          ref={ref}
                          src={i?.images?.[switchLanguage(i18n)]}
                          alt={index === 0 ? 'Slot com dinheiro grátis'
                            : index === 1 ? 'Tigrinho com Bônus de Slots'
                              : 'Coelhinho com bônus no depósito'}
                        />
                        {/* <img
                          ref={ref}
                          src={i?.images?.[switchLanguage(i18n)]}
                          alt="Responsible Gambling Brazil"
                        />
                        {/* <img
                          ref={ref}
                          src={
                            i?.images?.en
                            // windowSize?.width <= 768
                            //   ? i?.images?.en_mobile
                            //   : i?.images?.en
                          }
                          layout='fill'
                          // width={windowSize?.width <= 768 ? 320 : 465}
                          // height={windowSize?.width <= 768 ? 170 : 162}
                          alt="Responsible Gambling Brazil"
                          /> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className='flex flex-row mt-4 mb-2 gap-1 md:mt-4 mb-4 gap-4'>
                {
                  secondLayerBannerContent.map((i, index) => {
                    return (
                      <div 
                        key={`second-layer-banner-img-b-${index}`} 
                        className="cursor-pointer relative w-full"
                        onClick={
                          isLogin ?
                          () => {
                            if (i.link) {
                              handleSecondLayerBanner(i.link);
                            } 
                          }
                          :
                          () => dispatch(SettingActions.setLoginShow(true))
                        }
                      >
                        {
                          i18n.language === 'pt-BR' ?
                          <img 
                            src={windowSize?.width <= 768 ? i?.mobilePT_BG?.src : i?.desktopPT_BG?.src}
                            alt={index === 0 ? 'telegram BG'
                                : index === 1 ? 'lucky spin BG'
                                  : 'gift 5 credit BG'}
                          />
                          :
                          <img 
                            src={windowSize?.width <= 768 ? i?.mobileBG?.src : i?.desktopBG?.src}
                            alt={index === 0 ? 'telegram BG'
                                : index === 1 ? 'lucky spin BG'
                                  : 'gift 5 credit BG'}
                          />
                        }
                      </div>
                    )
                  })
                }
            </div>

            <div className='relative leading-unset overflow-hidden'>
              <JackpotComp value="175848" />
            </div>

            <div>
              <HomeGames data={data} recommendedGames={recommendedGames} hotGames={hotGames} />
            </div>

            <div className='relative pt-6 pb-4'>
              <div className='absolute m-auto left-0 right-0 bottom-10 md:bottom-0 lg:bottom-14 md:top-17 md:left-1/4 mt-2 z-[2] max-w-sm'>
                <div className='font-bold text-2xl'>
                  {isLogin
                    ? t('home.welcomeToPPX7')
                    : t('home.createAccountWithBetorSpin')}
                  {' '}
                  <span className='font-bold text-2xl' style={{ color: '#17c081' }}>
                    {
                      isLogin
                        ? t('home.letTheGameBegins')
                        : t('home.inSeconds')
                    }
                    !
                  </span>
                </div>
                <p className='text-white text-sm font-normal'>{t('home.uninterruptedGaming')}</p>
                <div className='flex gap-3 mt-4'>
                  {
                    isLogin
                      ? null
                      : (
                        <>
                          <Button
                            className='button-primary py-3 lg:py-2.5 shadow w-2/4 md:w-24'
                            onClick={() => dispatch(SettingActions.setRegisterShow(true))}
                          >
                            <div className='flex items-center justify-center'>
                              <span className='font-bold text-sm lg:text-xs uppercase'>
                                {t('home.register')}
                              </span>
                            </div>
                          </Button>
                          <Button
                            className='button-secondary py-3 lg:py-2.5 shadow w-2/4 md:w-24'
                            onClick={() => dispatch(SettingActions.setLoginShow(true))}
                          >
                            <div className='flex items-center justify-center'>
                              <span className='font-bold text-sm lg:text-xs uppercase'>
                                {t('home.login')}
                              </span>
                            </div>
                          </Button>
                        </>
                      )
                  }
                </div>
              </div>
              <div className='hidden md:block'>
                <Image src={HomeMiddleBanner} alt="" />
              </div>
              <div className='md:hidden m-auto text-center'>
                <Image src={HomeMiddleBannerMobile} alt="" />
              </div>
            </div>

            <div>
              <HomeHighestEarnings branch={data} />
            </div>

            {/* <FooterInfoSection />
          <HomeFaq /> */}
          </div>
        </Header>
        <div className='relative mx-auto'>
          <div className='mb-auto col-span-12 md:col-span-10 h-full'>
            <PartnerSection />
          </div>
        </div>

        {/* deposit status */}
        <div className={`${depositNotificationShow ? '' : 'hidden'}`}>
          <div
            tabIndex='-1'
            className='md:p-10 overflow-scroll bg-black bg-opacity-60 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
            role='dialog'
          >
            <div
              className='overlay-closeable'
              onClick={() => setDepositNotificationShow(false)}
            />
            <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
              <div className='mx-5'>
                <div className='flex justify-end'>
                  <button onClick={() => setDepositNotificationShow(false)}>
                    <CloseCircleFilled
                      fill='white'
                      style={{ fontSize: '20px' }}
                    />
                  </button>
                </div>
                <div className='mb-5 text-lg gap-6 font-medium text-white flex flex-col justify-center items-center'>
                  {paymentStatus === 'success' ? (
                    <Image alt='Deposit Sucess' src={DepositSuccess} width={60} height={60} />
                  ) : (
                    <Image alt='Deposit Fail' src={DepositFail} width={60} height={60} />
                  )}
                  <p>
                    {t('home.payment')}
                    {paymentStatus}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* campaign modal */}
      {/* <div
        className={`bottom-14 md:bottom-0 z-50 fixed campaign-pool-wrapper ${floatingOpen ? 'md:block' : 'hidden'
        }`}
      >
        <div className='flex flex-row cursor-pointer '>
          <div style={{ width: isMobile ? 140 : 180 }}>
            <Image
              src={IPLFloatIcon}
              onClick={() => {
                router.push('/ipl');
              }}
              alt=''
            />
          </div>
          <div>
            <CloseCircleFilled
              fill='black'
              style={{ fontSize: '16px', color: 'black' }}
              onClick={() => {
                setFloatingOpen(!(floatingOpen));
              }}
            />
          </div>
        </div>
      </div> */}
    </Layout>
  );
}

export default HomePage;
