/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-inner-declarations */
/* eslint-disable max-len */
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Link from 'next/link';
// import Marquee from 'react-fast-marquee';
import { Button } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Input } from 'antd';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  ChevronRight,
  gameDefaultImagepng,
  GameBadge,
  noDataIcon,
  homeFilterSlotsIconHover,
  homeFilterFishingIconHover,
  homeFilterLiveCasinoIconHover,
  homeFilterCardGamesIconHover,
  homeFilterRecentlyPlayedIconHover,
  homeFilterPopularIconHover,
  homeFilterAllIconHover,
  Icons8grid50,
} from '../../../images/index';
import HomeGamesComponent from './HomeGamesComponent';
import { homeFilterList } from '../../constant/homeFilterList';
import { SettingActions } from '../../reducers/settingReducer';
import { getGameCred } from '../../../api/getGameCred';
import HomeApp from '../../games/HomeApp';
import SkeletonBackground from '../../common/SkeletonBackground';
import { getGameType } from '../../constant/getGameType';

function HomeGames({ data, recommendedGames, hotGames }) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const isLogin = Cookies.get('accessToken');
  const userId = Cookies.get('userId');
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [selectedFilter, setSelectedFilter] = useState('');
  const localStorageAvailable = typeof window !== 'undefined' && window.localStorage;
  const [recentlyPlayedGamesArr, setRecentlyPlayedGamesArr] = useState(
    localStorageAvailable ? JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [] : [],
  );
  const windowSize = useSelector((state) => state.setting.windowSize);
  const [isSearch, setIsSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [initialGameList, setInitialGameList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(30);

  // const type = getGameType(category || 'slots');

  useEffect(() => {
    const handleStorageChange = () => {
      // Check if localStorage is available before using it
      if (typeof window !== 'undefined' && window.localStorage && isLogin) {
        const storedArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];
        setRecentlyPlayedGamesArr(storedArray);
      }
    };

    // Listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Retrieve from local storage on component mount
    handleStorageChange();

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Filter visible brand
    let result = [];
    const gamesResult = [];
    data.brandIcons = data?.brandIcons?.filter((x) => x.isVisible);

    data?.brandIcons?.map((x) => {
      result.push({
        _id: x.brandId,
        games: x.games, // all games show
        isVisible: x.isVisible,
        name: x.name,
        sorting: x.sorting,
        sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
        thumbnail: x.imageUrl,
      });
    });
    result = result.filter((i) => i.isVisible);

    result?.map((brand) => {
      brand?.games?.map((x) => {
        if (x.status !== '1') {
          gamesResult.push({
            ...x,
            brandThumbnail: brand.thumbnail,
            brandName: brand.name,
            brandId: brand._id.toString(),
          });
        }
      });
    });

    let allgames = [];
    allgames = gamesResult.filter(
      (x) => x?.thumbnail
        // && x?.gameId
        && x.status !== '1',
    );

    // allgames = allgames.sort((a, b) => {
    //   // Check if both games have the recommended property
    //   if (a.recommended !== undefined && b.recommended !== undefined) {
    //     return (
    //       b.recommended - a.recommended || a.name.localeCompare(b.name)
    //     );
    //   }

    //   // If one of the games doesn't have the recommended property, prioritize the one that has it
    //   if (a.recommended !== undefined) {
    //     return -1;
    //   }
    //   if (b.recommended !== undefined) {
    //     return 1;
    //   }

    //   // If neither game has the recommended property, just sort by name
    //   return a.name.localeCompare(b.name);
    // });

    setGameList(allgames);
    setInitialGameList(allgames);
  }, []);

  const saveToRecentPlayed = (x) => {
    const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

    const existingIndex = currentArray.findIndex((item) => item._id === x._id);

    let updatedArray;

    if (existingIndex !== -1) {
      updatedArray = [
        ...currentArray.slice(0, existingIndex),
        ...currentArray.slice(existingIndex + 1),
        x,
      ];
    } else {
      updatedArray = [...currentArray, x];
    }

    localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));
  };

  const gotoGame = async (k) => {
    if (!Cookies.get('accessToken')) {
      dispatch(SettingActions.setLoginShow(true));
    } else {
      dispatch(SettingActions.setLoading(true));
      const gameInfo = await getGameCred({
        brandId: k.brandId,
        type: k.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo) {
        saveToRecentPlayed(k);
        router.push(
          `/game_website?brandId=${k.brandId}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${k._id}`,
        );
      }
    }
  };

  const handleSearch = (search, filter) => {
    let result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase()));

    if (filter === 'popularity') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => (a.recommended || 0) - (b.recommended || 0));
    }
    if (filter === 'a-z') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }
    if (filter === 'z-a') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.name.localeCompare(a.name));
    }
    if (filter === 'recent') {
      const getRelatedType = (JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || []);
      // const specificIds = getRelatedType.filter((x) => x.type === type?.toString()).map((obj) => obj._id);
      // Removed filter because no filters needed for homepage games
      const specificIds = getRelatedType.map((obj) => obj._id);

      function sortBySpecificIdsFirst(array, specificIds) {
        // Create a set of specific IDs for faster lookup
        const specificIdsSet = new Set(specificIds);

        // Partition the array into two parts: objects with specific IDs and others
        const [specificObjects, otherObjects] = array.reduce((acc, obj) => {
          if (specificIdsSet.has(obj._id)) {
            acc[0].push(obj);
          } else {
            acc[1].push(obj);
          }
          return acc;
        }, [[], []]);

        // Sort the objects with specific IDs based on their order in the specificIds array
        specificObjects.sort((a, b) => specificIds.indexOf(a._id) - specificIds.indexOf(b._id));

        // Concatenate the sorted specific objects with the other objects
        return specificObjects.concat(otherObjects);
      }
      result = sortBySpecificIdsFirst(initialGameList, specificIds.toReversed());
    }
    setGameList(result);
  };

  function FilterBrand() {
    const popular = (
      <>
        {hotGames.length > 0 && (
          <div className='pt-2 pb-8'>
            <Swiper
              spaceBetween={7}
              slidesPerView={isMobile ? 3.1 : (windowSize?.width >= 1200
                ? 5
                : (windowSize?.width >= 768 ? 4.6 : windowSize?.width >= 580 ? 4 : 3))}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              loop
              modules={[Autoplay]}
            >
              {hotGames.sort((a, b) => a.sorting - b.sorting).map((x, i) => (
                <SwiperSlide key={`game-img-b-${i}`} className='cursor-pointer'>
                  <div
                    className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                    key={`${i}-${i._id}`}
                    onClick={() => {
                      gotoGame(x);
                    }}
                  >
                    <div className='absolute z-[2] leading-unset'>
                      <div style={{ width: 40 }} className='leading-unset'>
                        <Image src={GameBadge} alt='' />
                      </div>
                      <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                        <img src={x.brandThumbnail} alt='' />
                      </div>
                    </div>
                    <div className='relative game-overlay-img'>
                      <div className='game-overlay'>
                        <div className='triangle' />
                        <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                      </div>
                      <SkeletonBackground id={`skeleton-id-${i}`} />
                      <img
                        className='hidden w-full'
                        src={x.thumbnail || gameDefaultImagepng.src}
                        onLoad={(e) => {
                          document.getElementById(`skeleton-id-${i}`)?.remove();
                          e.target.classList?.remove('hidden');
                        }}
                        alt='Betting sites in Brazil'
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              {
                recommendedGames.map((x, i) => (
                  <SwiperSlide key={`game-img-b-${i}`} className='cursor-pointer'>
                    <div
                      className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                      key={`${i}-${i._id}`}
                      onClick={() => {
                        gotoGame(x);
                      }}
                    >
                      <div className='absolute z-[2] leading-unset'>
                        <div style={{ width: 40 }} className='leading-unset'>
                          <Image src={GameBadge} alt='' />
                        </div>
                        <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                          <img src={x.brandThumbnail} alt='' />
                        </div>
                      </div>
                      <div className='relative game-overlay-img'>
                        <div className='game-overlay'>
                          <div className='triangle' />
                          <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                        </div>
                        <SkeletonBackground id={`skeleton-id-${i}`} />
                        <img
                          className='hidden w-full'
                          src={x.thumbnail || gameDefaultImagepng.src}
                          onLoad={(e) => {
                            document.getElementById(`skeleton-id-${i}`)?.remove();
                            e.target.classList?.remove('hidden');
                          }}
                          alt='Betting sites in Brazil'
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        )}
      </>
    );
    const recent = (
      <>
        {recentlyPlayedGamesArr.length > 0 && (
          <div className='pt-2 pb-8'>
            <Swiper
              spaceBetween={7}
              slidesPerView={isMobile ? 3.1 : (windowSize?.width >= 1200
                ? 5
                : (windowSize?.width >= 768 ? 4.6 : windowSize?.width >= 580 ? 4 : 3))}
              autoplay={{
                delay: 3800,
                disableOnInteraction: false,
              }}
              loop
              modules={[Autoplay]}
            >
              {recentlyPlayedGamesArr.toReversed()
                .map((x, i) => (
                  <SwiperSlide key={`game-img-b-${i}`} className='cursor-pointer'>
                    <div
                      className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                      key={`${i}-${i._id}`}
                      onClick={() => {
                        gotoGame(x);
                      }}
                    >
                      <div className='absolute z-[2] leading-unset'>
                        <div style={{ width: 40 }} className='leading-unset'>
                          <Image src={GameBadge} alt='' />
                        </div>
                        <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                          <img src={x.brandThumbnail} alt='' />
                        </div>
                      </div>
                      <div className='relative game-overlay-img'>
                        <div className='game-overlay'>
                          <div className='triangle' />
                          <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                        </div>
                        <SkeletonBackground id={`skeleton-id-${i}`} />
                        <img
                          className='hidden w-full'
                          src={x.thumbnail || gameDefaultImagepng.src}
                          onLoad={(e) => {
                            document.getElementById(`skeleton-id-${i}`)?.remove();
                            e.target.classList?.remove('hidden');
                          }}
                          alt='Betting sites in Brazil'
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              {
                recommendedGames.map((x, i) => (
                  <SwiperSlide key={`game-img-b-${i}`} className='cursor-pointer'>
                    <div
                      className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                      key={`${i}-${i._id}`}
                      onClick={() => {
                        gotoGame(x);
                      }}
                    >
                      <div className='absolute z-[2] leading-unset'>
                        <div style={{ width: 40 }} className='leading-unset'>
                          <Image src={GameBadge} alt='' />
                        </div>
                        <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                          <img src={x.brandThumbnail} alt='' />
                        </div>
                      </div>
                      <div className='relative game-overlay-img'>
                        <div className='game-overlay'>
                          <div className='triangle' />
                          <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                        </div>
                        <SkeletonBackground id={`skeleton-id-${i}`} />
                        <img
                          className='hidden w-full'
                          src={x.thumbnail || gameDefaultImagepng.src}
                          onLoad={(e) => {
                            document.getElementById(`skeleton-id-${i}`)?.remove();
                            e.target.classList?.remove('hidden');
                          }}
                          alt='Betting sites in Brazil'
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        )}
      </>
    );
    const live = (
      <div className='mb-8'>
        <div className='flex justify-between mb-2'>
          <div className='flex items-center'>
            <div className='leading-none mr-1'>
              <Image src={homeFilterLiveCasinoIconHover} alt='' />
            </div>
            <div className='text-lg font-bold'>{t('home.liveCasino')}</div>
          </div>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <Link
                href='/live-casino'
              >
                <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                  <Image src={Icons8grid50} alt='' />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <HomeGamesComponent
          data={data}
          type={8}
          gotoGame={gotoGame}
          delay={2500}
        />
      </div>
    );
    const instant = (
      <div className='mb-8'>
        <div className='flex justify-between mb-2'>
          <div className='flex items-center'>
            <div className='leading-none mr-1'>
              <Image src={homeFilterFishingIconHover} alt='' />
            </div>
            <div className='text-lg font-bold'>{t('home.instantGames')}</div>
          </div>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <Link
                href='/instants'
              >
                <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                  <Image src={Icons8grid50} alt='' />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <HomeGamesComponent
          data={data}
          type={1}
          gotoGame={gotoGame}
          delay={2800}
        />
      </div>
    );
    const slots = (
      <div className='mb-8'>
        <div className='flex justify-between mb-2'>
          <div className='flex items-center'>
            <div className='leading-none mr-1'>
              <Image src={homeFilterSlotsIconHover} alt='' />
            </div>
            <div className='text-lg font-bold'>{t('home.slot')}</div>
          </div>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <Link
                href='/slots'
              >
                <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                  <Image src={Icons8grid50} alt='' />
                </div>

              </Link>
            </div>

          </div>
        </div>
        <HomeGamesComponent
          data={data}
          type={0}
          gotoGame={gotoGame}
          delay={3000}
        />
      </div>
    );
    const table = (
      <div className='mb-8'>
        <div className='flex justify-between mb-2'>
          <div className='flex items-center'>
            <div className='leading-none mr-1'>
              <Image src={homeFilterCardGamesIconHover} alt='' />
            </div>
            <div className='text-lg font-bold'>{t('game.tableGames')}</div>
          </div>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <Link
                href='/tables'
              >
                <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                  <Image src={Icons8grid50} alt='' />
                </div>

              </Link>
            </div>

          </div>
        </div>
        <HomeGamesComponent
          data={data}
          type={3}
          gotoGame={gotoGame}
          delay={3000}
        />
      </div>
    );

    if (selectedFilter === 0) {
      return <HomeApp data={data} name='' category='slots' />;
    } if (selectedFilter === 'recent') {
      return recent;
    } if (selectedFilter === 'popular') {
      return popular;
    } if (selectedFilter === 8) {
      return <HomeApp data={data} name='' category='live-casino' />;
    } if (selectedFilter === 1) {
      return <HomeApp data={data} name='' category='instant-games' />;
    } if (selectedFilter === 4) {
      return <HomeApp data={data} name='' category='tables' />;
    }
    return (
      <>
        {
          recentlyPlayedGamesArr.length > 0 && (
            <>
              <div className='flex justify-between'>
                <div className='flex items-center'>
                  <div className='leading-none mr-1'>
                    <Image src={homeFilterRecentlyPlayedIconHover} alt='' />
                  </div>
                  <div className='text-lg font-bold'>{t('game.recentlyPlayed')}</div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center'>
                    <Link
                      href='/live-casino'
                    >
                      <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                        <Image src={Icons8grid50} alt='' />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {recent}
            </>
          )
        }
        {
          hotGames.length > 0 && (
            <>
              <div className='flex justify-between'>
                <div className='flex items-center'>
                  <div className='leading-none mr-1'>
                    <Image src={homeFilterPopularIconHover} alt='' />
                  </div>
                  <div className='text-lg font-bold'>{t('game.popular')}</div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center'>
                    <Link
                      href='/live-casino'
                    >
                      <div className='leading-none mr-1' style={{ width: 18, filter: 'invert(62%) sepia(48%) saturate(399%) hue-rotate(91deg) brightness(150%) contrast(88%)' }}>
                        <Image src={Icons8grid50} alt='' />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {popular}
            </>
          )
        }
        {slots}
        {instant}
        {live}
        {table}
      </>
    );
  }

  const scrolltoElement = () => {
    const topElement = document.getElementById('game-container');
    if (topElement) topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div id='game-container' className='pt-2 md:pt-12'>
      {
        isSearch
          ? (
            <div className='relative my-2 sticky top-14 z-[5] bg-dark'>
              <Input
                className='w-full rounded-lg h-10 home-search-input bg-dark text-white px-2'
                placeholder={t('home.gameName')}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  handleSearch(e.target.value, selectedValue);
                }}
              />
              <p
                className='absolute top-2.5 right-3 cursor-pointer text-xs font-normal text-light-black'
                onClick={() => {
                  setIsSearch(false);
                  setSearchText('');
                }}
              >
                {t('home.cancel')}
              </p>
            </div>
          )
          : (
            <div className='hidden md:block relative bg-customgray sticky top-16 z-[5] mb-4'>
              <div className='w-full scrollbar-hide flex-container justify-start items-center align-center p-2 pr-10'>
                {
                  homeFilterList.map((item, index) => (
                    <div
                      key={index}
                      className={`flex-item-tab flex text-center items-center cursor-pointer gap-2 py-2 rounded px-4 mr-2 hover:bg-gray-600 default-games-tab ${selectedFilter === item.type ? 'active-games-tab' : 'bg-customgray'}  `}
                      // style={isMobile ? item.style : {}}
                      onClick={() => {
                        setSelectedFilter(item.type);
                        scrolltoElement();
                      }}
                    >
                      <div className={`leading-unset m-auto inline-block ${isMobile && 'm-auto'}`} style={{ width: 16 }}>
                        <Image src={item.iconHovered} alt='ppx7' />
                      </div>
                      <p className='text-xs font-normal inline-block'>{t(item.name)}</p>
                    </div>
                  ))
                }
              </div>
              <div className='bg-customgray absolute right-0 top-0 h-full flex px-2'>
                <Button
                  className='leading-unset'
                  onClick={() => {
                    setIsSearch(true);
                    scrolltoElement();
                  }}
                >
                  <SearchOutlined style={{ fontSize: 16, color: '#1FEC9E' }} />
                </Button>
              </div>
            </div>
          )
      }

      {
        isSearch
          ? (
            searchText
              ? (
                <div className='text-center pt-4'>
                  <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-5 gap-y-2 gap-2'>
                    {(gameList || [])?.slice(0, limit)?.map((x, i) => (
                      <div
                        className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                        key={`${i}-${x._id}`}
                        onClick={() => {
                          const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

                          const existingIndex = currentArray.findIndex((item) => item._id === x._id);

                          let updatedArray;

                          if (existingIndex !== -1) {
                            updatedArray = [
                              ...currentArray.slice(0, existingIndex),
                              ...currentArray.slice(existingIndex + 1),
                              x,
                            ];
                          } else {
                            updatedArray = [...currentArray, x];
                          }

                          localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

                          gotoGame(x);
                          setSearchText('');
                        }}
                      >
                        <div className='absolute z-[2] leading-unset'>
                          <div style={{ width: 40 }} className='leading-unset'>
                            <Image src={GameBadge} alt='' />
                          </div>
                          <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                            <img src={x.brandThumbnail} alt='' />
                          </div>
                        </div>
                        <div className='relative game-overlay-img'>
                          <div className='game-overlay'>
                            <div className='triangle' />
                            <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                          </div>
                          <SkeletonBackground id={`skeleton-id-${i}`} />
                          <img
                            className='hidden'
                            src={x.thumbnail || gameDefaultImagepng.src}
                            onLoad={(e) => {
                              document.getElementById(`skeleton-id-${i}`)?.remove();
                              e.target.classList?.remove('hidden');
                            }}
                            alt='Betting sites in Brazil'
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {
                    (limit < gameList.length)
                    && (
                      <div className='mt-4'>
                        <Button
                          className='button-secondary py-3 lg:py-2.5 px-8'
                          onClick={() => {
                            const randomLoadTime = Math.floor(Math.random() * 2500) + 500;
                            setLoading(true);
                            setTimeout(() => {
                              setLimit(limit + 30);
                              setLoading(false);
                            }, randomLoadTime);
                          }}
                        >
                          <div className='flex items-center justify-center'>
                            <span className='font-bold text-sm lg:text-xs uppercase'>
                              {t('home.loadMore')}
                            </span>
                            {
                              loading
                              && <span className='button-loader ml-2' />
                            }
                          </div>
                        </Button>
                      </div>
                    )
                  }
                </div>
              )
              : (
                <div className='w-full'>
                  <div className='columns text-center' style={{ filter: 'invert(50%) sepia(88%) saturate(368%) hue-rotate(93deg) brightness(94%) contrast(92%)' }}>
                    <Image
                      style={{ opacity: 0.9 }}
                      src={noDataIcon}
                      alt='Legal online gambling in Brazil'
                    />
                  </div>
                  <p className='text-light-black text-center'>{t('home.noSearchResults')}</p>
                </div>
              )
          )
          : (
            <>
              <div className='flex justify-between items-center leading-unset md:hidden mb-2'>
                <div className='flex items-center'>
                  <div className='leading-none mr-1'>
                    <Image src={homeFilterAllIconHover} alt='' />
                  </div>
                  <div className='text-xl md:text-lg font-bold'>{t('home.games')}</div>
                </div>
                <div className='bg-customgray rounded px-3 py-3 leading-unset'>
                  <Button
                    className='leading-unset'
                    onClick={() => {
                      setIsSearch(true);
                      scrolltoElement();
                    }}
                  >
                    <SearchOutlined style={{ fontSize: 16, color: '#1FEC9E' }} />
                  </Button>
                </div>
              </div>
              <div className='md:hidden relative bg-customgray pt-2 pb-2 sticky top-16 z-[5] mb-4'>
                <div className='w-full scrollbar-hide flex-container justify-start items-center align-center'>
                  {
                    homeFilterList.map((item, index) => (
                      <div
                        key={index}
                        className={`flex-item-tab flex text-center items-center cursor-pointer gap-2 py-2 rounded px-4 mr-2 hover:bg-gray-600 default-games-tab ${selectedFilter === item.type ? 'active-games-tab' : 'bg-customgray'}  `}
                        // style={isMobile ? item.style : {}}
                        onClick={() => {
                          setSelectedFilter(item.type);
                          scrolltoElement();
                        }}
                      >
                        <div className={`leading-unset m-auto inline-block ${isMobile && 'm-auto'}`} style={{ width: 16 }}>
                          <Image src={item.iconHovered} alt='ppx7' />
                        </div>
                        <p className='text-xs font-normal inline-block'>{t(item.name)}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
              {/* <div className='md:hidden relative rounded mb-6 bg-dark py-2 sticky top-14 z-[5]'>
                <Swiper
                  spaceBetween={7}
                  slidesPerView={windowSize?.width >= 700
                    ? 5.5
                    : (windowSize?.width >= 600 ? 5.1 : (windowSize?.width >= 540 ? 4.1 : windowSize?.width >= 480 ? 3.8 : (windowSize?.width >= 400 ? 3.4 : 3)))}
                >
                  {
                    homeFilterList.map((item, index) => (
                      <SwiperSlide key={`game-categories-${index}`} className='cursor-pointer'>
                        <div
                          key={index}
                          className={`h-24 flex items-center justify-center text-center items-center cursor-pointer gap-2 rounded px-2 hover:bg-gray-600 ${selectedFilter === item.type ? 'active-button' : 'bg-customgray'}  `}
                          onClick={() => {
                            setSelectedFilter(item.type);
                          }}
                        >
                          <div>
                            <div className={`leading-unset mx-auto mb-3 ${isMobile && 'mx-auto'}`} style={{ width: 26 }}>
                              <Image src={item.iconHovered} alt='ppx7' />
                            </div>
                            <p className='text-base font-semibold leading-none'>{t(item.name)}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div> */}

              <FilterBrand />
            </>
          )
      }
    </div>
  );
}

export default HomeGames;
