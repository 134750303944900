import React, { useRef } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { SettingActions } from '../reducers/settingReducer';

export default function App({ show, toggleShow, info }) {
  const dispatch = useDispatch();
  const isLanguage = useSelector((state) => state.setting.locale);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const router = useRouter();
  // const isMobile = useSelector((state) => state.setting.isMobile);

  // Cookie by Minutes
  // const inOneMinutes = new Date(new Date().getTime() + 1 * 60 * 1000);

  const handleClick = (index, e) => {
    if (info?.[index]?.linkUrl) {
      dispatch(SettingActions.setLoading(true));
      router.push(info?.[index]?.linkUrl);
    }
  };

  if (show) {
    return (
      <div
        tabIndex='-1'
        className={
          show
            ? 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
            : 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
        }
      >
        <div
          className='overlay-closeable'
          onClick={() => {
            toggleShow(false);
            Cookies.set('popup', true);
            Cookies.set('refreshDate', (new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0))));
          }}
        />
        <div
          className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'
          style={{ background: 'none' }}
        >
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:top-1'>
            <button
              className='rounded-full close-button relative z-10'
              onClick={() => {
                toggleShow(false);
                Cookies.set('popup', true);
                Cookies.set('refreshDate', (new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0))));
              }}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
          <div className='place-items-center grid'>
            <div className='relative'>
              <div className='custom-carousel-buttons home-sport-carousel'>
                <button className='control-arrow control-prev' onClick={() => prevRef?.current?.click()}>
                  <span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-chevron-compact-left' viewBox='0 0 16 16'>
                      <path fillRule='evenodd' d='M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z' />
                    </svg>
                  </span>
                </button>
                <button className='control-arrow control-next' onClick={() => nextRef?.current?.click()}>
                  <span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-chevron-compact-right' viewBox='0 0 16 16'>
                      <path fillRule='evenodd' d='M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z' />
                    </svg>
                  </span>
                </button>
              </div>
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay
                emulateTouch
                // stopOnHover
                useKeyboardArrows
                showIndicators
                // showIndicators={false}
                onClickItem={handleClick}
                // onChange={console.log}
                className='cursor-pointer'
                renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (hasPrev) && (
                  <button ref={prevRef} className='hidden control-arrow control-prev' onClick={clickHandler}>
                    <span>
                      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-chevron-compact-left' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z' />
                      </svg>
                    </span>
                  </button>
                )}
                renderArrowNext={(clickHandler, hasNext, labelNext) => hasNext && (
                  <button ref={nextRef} className='hidden control-arrow control-next' onClick={clickHandler}>
                    <span>
                      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-chevron-compact-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z' />
                      </svg>
                    </span>
                  </button>
                )}
              >
                {
                  info.map((x, i) => (
                    <div key={i}>
                      <img
                        src={x?.images?.[isLanguage]}
                        className='cursor-pointer'
                        alt='Betting sites in Brazil'
                      />
                    </div>
                  ))
                }
              </Carousel>
            </div>

          </div>
        </div>
      </div>
    );
  }
  return null;
}
