import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { SettingActions } from '../reducers/settingReducer';
import {
  HomeSuperWin,
  HomeSuperWinMobile,
  HomeSuperWinButton,
} from '../../images/index';
import { formatAmount } from '../common/common';

function JackpotComp({
  value,
}) {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [numbers, setNumbers] = useState([]);
  const { t, i18n } = useTranslation('common');

  // const elementWindow = typeof window !== 'undefined'
  // && (document.getElementById('page-container') || null);
  // const lastScrollY = elementWindow?.offsetWidth || 0;

  useEffect(() => {
    const amount = formatAmount(value).split(',')[0].split('');
    // setNumbers(amount);
    setNumbers(['R', '$', '1', '7', '5', '.', '8', '4', '8']);
  }, []);

  const _renderChildNumbers = (excludeNumber) => {
    const numbers = [];

    // Generate random numbers until we have 10 unique numbers
    while (numbers.length < 10) {
      const randomNumber = Math.floor(Math.random() * 11); // Generate a random number between 0 and 10
      if (randomNumber !== excludeNumber && !numbers.includes(randomNumber)) {
        numbers.push(randomNumber);
      }
    }
    return numbers.map((number) => (
      <div
        key={number}
        className='van-rolling-text-item__item'
      >
        {number}
      </div>
    ));
  };

  return (
    <Link
      href='/jackpot'
    >
      <div>
        <div className='shine-2 overflow-hidden'>
          <Image src={isMobile ? HomeSuperWinMobile : HomeSuperWin} alt='ppx7' />
        </div>
        <div className='super-win-wrap text-2xl'>
          {/* <h2>175,848 BRL</h2> */}

          <div className='numbox'>

            <div
              className='van-rolling-text van-rolling-alignment'
            >
              {i18n.language === 'pt-BR'
                ? (
                  <span className='van-rolling-text-item__item'>
                    {/* {depositCurrency} */}
                  </span>
                )
                : (
                  <span className='van-rolling-text-item__item'>
                    {/* R */}
                  </span>
                )}
              {
                numbers?.map((x, i) => (
                  <div
                    key={i}
                    className='van-rolling-text-item van-rolling-text-item--down'
                    style={{
                      '--van-delay': `${0}.${(2 * i)}s`,
                      '--van-duration': '1s',
                      '--van-translate': '-800px',
                    }}
                  >
                    <div className='van-rolling-text-item__box van-rolling-text-item__box--animate'>
                      <div className='van-rolling-text-item__item'>
                        {x}
                      </div>
                      {_renderChildNumbers(x)}
                    </div>
                  </div>
                ))
              }
            </div>

          </div>
        </div>
        <div
          className='super-win-button leading-none relative cursor-pointer hover:opacity-70 transition duration-200 ease-out hidden md:block'
        >
          {/* <Image src={HomeSuperWinButton} alt='' />
        <p className='leading-none font-bold text-4xs md:text-2xs lg:text-base text-black uppercase'>
          Sorteio
        </p> */}
          <Button
            className='flex rounded-full text-center items-center justify-center px-2 py-2 sm:px-4 lg:px-8 md:py-3 button-primary'
          >
            <p className='text-black font-bold text-lg uppercase opacity-60'>
              {t('common.prizes')}
            </p>
          </Button>
        </div>
      </div>
    </Link>
  );
}

export default JackpotComp;
