/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import Image from 'next/image';
import Cookies from 'js-cookie';
import Provider from './provider';
import Gameh5 from './gameh5';
import { SettingActions } from '../reducers/settingReducer';
import { getGameType, getGameName } from '../constant/getGameType';

import {
  ChevronDown,
} from '../../images';
import BackToTopButton from '../common/BackToTop';

const { Search } = Input;

function HomeApp({ data, name, category }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const elementWindow = typeof window !== 'undefined'
    && (document.getElementById('page-container') || null);
  const { t } = useTranslation('common');
  const [gameBrands, setGameBrands] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [initialGameList, setInitialGameList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(21);
  const [selectedValue, setSelectedValue] = useState('');
  const userId = Cookies.get('userId');

  const type = getGameType(category || 'slots');

  const fetchGames = async (brands, id) => {
    setGameList([]);
    setInitialGameList([]);
    // setLoading(true);

    const filterBrand = brands.find((x) => x._id === id);
    const filterType = filterBrand?.games
      ?.filter((x) => x.type === type.toString())
      .filter((x) => x.status === '0')
      .filter((x) => x.thumbnail);
    if (filterType) {
      filterType.map((x) => (x.brandThumbnail = filterBrand.thumbnail));
    }
    setGameList(filterType);
    setInitialGameList(filterType);
    // setLoading(false);
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Do something with the new URL
      setSelectedVendor('');
    };

    // Listen to the router's routeChangeComplete event
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // Empty dependenc

  useEffect(() => {
    const selectedProvider = selectedVendor || name;
    if (selectedProvider) {
      const i = gameBrands.find((x) => x.name === selectedProvider);
      if (i) {
        setSelectedVendor(i._id);
      }
    }
    // dispatch(SettingActions.setLoading(false));
  }, [gameBrands, category, name]);

  useEffect(() => {
    let result = [];

    // Replace icon from brand icons
    // Filter visible brand
    data.brandIcons = data.brandIcons.filter((x) => x.isVisible);

    data.brandIcons.map((x) => {
      if (x[`imageUrl_${type}`]) {
        result.push({
          _id: x.brandId,
          games: x.games, // all games show
          isVisible: x.isVisible,
          name: x.name,
          displayName: x.displayName,
          sorting: x.sorting,
          sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
          thumbnail: x[`imageUrl_${type}_sec`] || x[`imageUrl_${type}`],
        });
      }
    });

    if (type === 12 || type === '12') {
      result = _.sortBy(result, ['sortings[0]']);
    } else if (type === 8 || type === '8') {
      result = _.sortBy(result, ['sortings[1]']);
    } else if (type === 0 || type === '0') {
      result = _.sortBy(result, ['sortings[2]']);
    } else if (type === 4 || type === '4') {
      result = _.sortBy(result, ['sortings[3]']);
    } else if (type === 1 || type === '1') {
      result = _.sortBy(result, ['sortings[4]']);
    } else if (type === 3 || type === '3') {
      result = _.sortBy(result, ['sortings[6]']);
    }

    result = result.map((x) => {
      x.games = x.games.filter((x1) => x1.type === type.toString());
      return x;
    });
    result = result.filter((i) => i.isVisible && i.games.length > 0);
    setGameBrands(result);

    if (selectedVendor) {
      fetchGames(result, selectedVendor);
    } else {
      const gamesResult = [];

      result.map((brand) => {
        brand.games.map((x) => {
          if (x.status !== '1') {
            gamesResult.push({
              ...x,
              brandThumbnail: brand.thumbnail,
              brandName: brand.name,
              brandId: brand._id.toString(),
            });
          }
        });
      });

      let allgames = [];
      allgames = gamesResult.filter(
        (x) => x.type === type.toString()
          && x?.thumbnail
          // && x?.gameId
          && x.status !== '1',
      );

      // allgames = allgames.sort((a, b) => {
      //   // Check if both games have the recommended property
      //   if (a.recommended !== undefined && b.recommended !== undefined) {
      //     return (
      //       b.recommended - a.recommended || a.name.localeCompare(b.name)
      //     );
      //   }

      //   // If one of the games doesn't have the recommended property, prioritize the one that has it
      //   if (a.recommended !== undefined) {
      //     return -1;
      //   }
      //   if (b.recommended !== undefined) {
      //     return 1;
      //   }

      //   // If neither game has the recommended property, just sort by name
      //   return a.name.localeCompare(b.name);
      // });

      setGameList(allgames);
      setInitialGameList(allgames);
      setLoading(false);
    }
  }, [router.asPath, type, selectedVendor]);

  const handleSearch = (search, filter) => {
    let result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase()));

    if (filter === 'popularity') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => (a.recommended || 0) - (b.recommended || 0));
    }
    if (filter === 'a-z') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }
    if (filter === 'z-a') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.name.localeCompare(a.name));
    }
    if (filter === 'recent') {
      const getRelatedType = (JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || []);
      const specificIds = getRelatedType.filter((x) => x.type === type?.toString()).map((obj) => obj._id);

      function sortBySpecificIdsFirst(array, specificIds) {
        // Create a set of specific IDs for faster lookup
        const specificIdsSet = new Set(specificIds);

        // Partition the array into two parts: objects with specific IDs and others
        const [specificObjects, otherObjects] = array.reduce((acc, obj) => {
          if (specificIdsSet.has(obj._id)) {
            acc[0].push(obj);
          } else {
            acc[1].push(obj);
          }
          return acc;
        }, [[], []]);

        // Sort the objects with specific IDs based on their order in the specificIds array
        specificObjects.sort((a, b) => specificIds.indexOf(a._id) - specificIds.indexOf(b._id));

        // Concatenate the sorted specific objects with the other objects
        return specificObjects.concat(otherObjects);
      }
      result = sortBySpecificIdsFirst(initialGameList, specificIds.toReversed());
    }

    setGameList(result);
  };

  return (
    <div>

      <div className='relative'>

        <Provider
          data={data}
          gameBrands={gameBrands}
          gameList={gameList}
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setSearchText={setSearchText}
          localCategory={category}
          className='sticky top-28 z-[5] theme-bg pb-2 pt-2 px-0'
        />

        <div ref={elementRef} style={{ minHeight: '5vh' }}>
          <Gameh5
            games={gameList}
            limit={limit}
            selectedVendor={selectedVendor}
            loading={loading}
            setSearchText={setSearchText}
          />
          <br />
          <br />
          <br />
          <br />
          {/* <div id='loader-bottom-wrapper' className='text-center'>
            <button className='rounded-full button-secondary-transparent px-8 py-3' onClick={() => handleLimit()}>
              <p className='font-medium text-sm'>Load more</p>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HomeApp;
